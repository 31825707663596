.custom-dropdown {
  border: none !important;
  box-shadow: none !important;
  margin: 0;
  padding: 0;
}

.custom-dropdown .menu {
  margin-top: 5px;
  border: 1px solid #d4d4d5 !important;
  /* Establece un borde consistente */
  border-radius: 4px !important;
  /* Asegura esquinas redondeadas */
  box-shadow: none !important;
  /* Quita cualquier sombra que pueda ocultar el borde */
}


.custom-dropdown:focus,
.custom-dropdown .menu:focus {
  outline: none !important;
  /* Quita el delineado azul */
  box-shadow: none !important;
  /* Quita cualquier sombra alrededor del dropdown */
}

.custom-dropdown .dropdown-content {
  padding: 10px;
  width: 200px;
  /* Ajusta el ancho del menú si es necesario */
}

/* Deshabilitar el cambio de color al hacer hover */
.custom-dropdown .menu>div:hover {
  background-color: transparent !important;
  /* Deshabilita el fondo en hover */
  box-shadow: none !important;
  /* Elimina cualquier sombra al hacer hover */
}

/* Ajustar el estilo de los select */
.custom-dropdown .menu>div>select {
  background-color: white;
  /* Fondo blanco para los select */
  border: 1px solid #ccc;
  /* Borde gris claro */
  border-radius: 4px;
  /* Esquinas redondeadas */
  padding: 8px;
  /* Espaciado interno */
  color: #333;
  /* Color del texto */
  width: 100%;
  /* Asegurarse de que el select ocupe el ancho del contenedor */
  cursor: pointer;
}

/* Eliminar el contorno al enfocar */
.custom-dropdown .menu>div>select:focus {
  outline: none;
  /* Eliminar contorno por defecto */
  border-color: #007bff;
  /* Opcional: Cambiar borde al enfocar */
}

.button-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.download-upload-buttons {
  display: flex;
  gap: 10px;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.download-button, .upload-button {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
}

.custom-container {
  padding-top: 20px;
  width: 100%;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .custom-container {
    padding-top: 20px;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .button-bar {
    flex-direction: column;
    gap: 10px;
  }

  .download-upload-buttons {
    justify-content: space-between;
  }
}