.custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .select-box {
    background-color: #6c757d; /* Gris oscuro (similar a btn-secondary) */
    color: white; /* Texto blanco */
    border: none; /* Sin borde adicional */
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    border-radius: 0.25rem;
  }
  
  .select-box option {
    color: black; /* Opciones con texto negro */
    background-color: white; /* Fondo blanco para las opciones */
  }
  
  /* Añadir un borde como cajita */
  .select-box {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); /* Sombra ligera */
  }
  
  .select-box:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Borde más definido al hacer focus */
  }
  