/* Position and sizing of burger button */

.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    left: 20px;
    top: 24px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
    background: #D50032;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */

.bm-cross {
    background: #fff;
}

/* General sidebar styles */

.bm-menu {
    background: #d50032;
    padding: 2.5em 0 0;
    font-size: 1.35em;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
    color: #b8b7ad;
    a {
        color: white;
    }
    a:hover {
        color: ;
    }
}

/* Styling of overlay */

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
