.btn-hover {
    all: unset;
    cursor: pointer;
    color: black; 
    transition: color 0.3s ease; 
}

.btn-hover:hover {
    color: rgb(0, 153, 255); 
}
