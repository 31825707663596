.breadcrumb-container {
    /* position: relative; */
    /* top: 60px; */
    width: max-content;
}

ol.breadcrumb-list {
    background-color: transparent;
    margin: 0;
}

.breadcrumb-container .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
}

.breadcrumb-item {
    color: #d50032;
}

.breadcrumb-item a:hover {
    color: #333f48;
}

.breadcrumb-item--active {
    color: #333f48;
}
