.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
}

.btn-primary:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    opacity: 0.85;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $primary-color;
    border-color: $primary-color;
}

.btn-outline-primary {
    color: $primary-color;
}

.btn-outline-primary:hover {
    background-color: $primary-color;
    border-color: $primary-color;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: $primary-color;
}

.bm-item-list a:hover {
    background-color: #333f48;
    color: white;
    text-decoration: none;
}

.container {
    padding: 15px;
}
.form-control.is-invalid.select-reset > div {
    border-color: #dc3545;
}
.form-control {
    height: calc(2.25rem + 7px);
}

.is-invalid .invalid-feedback {
    display: block;
}

div.is-invalid label.uploader {
    outline-color: #dc3545 !important;
    border-radius: 5px !important;
}

.no-border {
    border: none !important;
}

.page-link {
    color: $primary-color;
}

.page-item.active .page-link {
    background-color: $primary-color;
    border-color: $primary-color;
}
.select-reset > div {
    border-width: 0 !important;
    background-color: #fff !important;
}

.text-primary {
    color: $primary-color !important;
}

.material-livery-list .MuiTableSortLabel-root{
    font-weight: 600;
}

.material-livery-list .MuiTableSortLabel-root:hover,
.material-livery-list .MuiTableSortLabel-root.MuiTableSortLabel-active,
.material-livery-list .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #fff;
}

.material-livery-list .MTableToolbar-title-9 .MuiTypography-h6 {
    font-size: 1.7rem;
    font-weight: 600;
    color: #707070;
}

.material-livery-list .MuiTypography-caption {
    color: #d50032;
}

.material-livery-list .MuiIconButton-root {
    color: #000;
}

.MuiButtonBase-root.MuiIconButton-root.Mui-disabled{
    color: #8e8e8e;
}

.material-livery-list .MuiInputBase-input, .material-livery-list .MuiIconButton-root.Mui-disabled {
    font-weight: 600;
}

.select-border-bottom {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    border-radius: 0;
    color: #707070;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.select-border-bottom .Select-value-label {
    color: #707070 !important;
}

.select-border-bottom.bold .Select-value-label {
    font-weight: bold;
}

.select-labeled {
    position: relative;
}

.select-labeled label {
    position: absolute;
    color: #626262;
    z-index: 2;
    font-size: 0.7rem;
    left: 0.9rem;
    top: 0.7em;
}

.select-labeled .Select .Select-control {
    padding-top: 1.5rem;
}

.select-labeled .Select .Select-control .Select-multi-value-wrapper {
    position: relative;
}

.modalItinerary .select-labeled .Select .Select-control {
    padding-top: 1rem !important;
}

.modalItinerary .select-labeled label {
    left: 1.3rem !important;
    top: 1.5em !important;
}