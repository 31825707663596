.button-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.download-upload-buttons {
  display: flex;
  gap: 20px;
}

.deleteButton {
  display: flex;
  align-items: center;
  height: 1rem;
  color: #6c757d;
}

.deleteButton:hover {
  color:red; 
}

.activateButton {
  display: flex;
  align-items: center;
  height: 1rem;
  color: #6c757d;
}

.activateButton:hover {
  color:green; 
}


.search-input {
  width: 800px;
  margin-bottom: 10px;
}

.filter-group {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  align-items: center;
}

.filter-toggle {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 5px 0;
  gap: 8px;
}

.filter-toggle input[type="checkbox"] {
  margin: 0; /* Elimina márgenes innecesarios */
  position: static; /* Evita desajustes con `top` */
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.filter-toggle label {
  font-size: 14px;
  cursor: pointer;
  user-select: none; /* Prevents text selection when clicking */
  color: #495057;
  white-space: nowrap;
  margin-bottom: 0;
}

/* Add a subtle hover effect */
.filter-toggle:hover {
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* Make the whole filter-toggle clickable */
.filter-section .filter-toggle {
  margin-top: 26px; /* Align with other filter elements that have labels */
}

.main-container {
  padding: 5px 0;
}

.driver-card {
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin-bottom: 15px;
}

.driver-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.driver-name {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.station-badge {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  min-width: 80px;
  text-align: center;
}

.driver-meta {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.driver-meta-item {
  display: flex;
  flex-direction: column;
  min-width: 120px;
}
.meta-label {
  font-size: 12px;
  color: #6c757d;
}
.meta-value {
  font-size: 14px;
}

.action-group {
  display: flex;
  gap: 10px;
}

.action-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px;
}

.archive-button {
  border: none;
  background: none;
  cursor: pointer;
  color: #6c757d;
  margin-left: 8px;
}

.status-badge {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.summary-card {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.summary-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #495057;
}
.summary-value {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #212529;
}
.summary-label {
  font-size: 14px;
  color: #6c757d;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.download-button,
.upload-button {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.custom-container {
  padding-top: 20px;
  width: 100%;
  padding-right: 200px;
  padding-left: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .custom-container {
    padding-top: 20px;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .button-bar {
    flex-direction: column;
    gap: 10px;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .download-upload-buttons {
    justify-content: space-between;
  }

  .search-input {
    width: 100%;
  }

  .driver-details {
    grid-template-columns: 1fr;
  }
  .driver-meta {
    flex-direction: column;
    gap: 5px;
  }

  .filter-group {
    flex-direction: column;
  }
}