:root {
    --primary-color: #D50032;
}

#file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

#file + label {
    color: white;
    background-color: var(--primary-color);
    display: inline-block;
    margin-bottom: 0;
    max-width: 5rem;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file__button {
    background: var(--primary-color);
    border: none;
    border-left: 1px solid white;
    color: white;
    margin-right: -0.5rem;
}

.loaded {
    border-right: 1px solid white !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.loaded--left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.loaded--left:focus {
    box-shadow: none !important;
}

.iconUpload {
    font-size: inherit;
    width: 16px;
    height: 16px;
    padding-bottom: 0.25rem;
}
