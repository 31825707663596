
  @media (min-width: 576px) {
      .container-sm, .container {
          max-width: 540px;
      }
  }
  @media (min-width: 768px) {
      .container-md, .container-sm, .container {
          max-width: 720px;
      }
  }
  @media (min-width: 992px) {
      .container-lg, .container-md, .container-sm, .container {
          max-width: 960px;
      }
  }
  @media (min-width: 1200px) {
      .container-xl, .container-lg, .container-md, .container-sm, .container {
          max-width: 1140px;
      }
  }
  @media (min-width: 1400px) {
      .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
          max-width: 1320px;
      }
  }

  .calendar-content{
    margin-left: 200px;
    margin-right: 200px;
  }

  @media (max-width: 1600px) {
    .calendar-content{
      margin-left: 80px;
      margin-right: 80px;
    }
  }
  
  @media (max-width: 1200px) {
    .calendar-content{
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .button-group {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  button {
    padding: 6px 12px;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
  }
  button.active {
    background-color: #e9ecef;
  }
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid #dee2e6;
    border-bottom: none;
    border-right: none;
    margin-left: 50px;
    margin-right: 50px;

  }
  .day-header {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    background-color: #f8f9fa;
  }
  .day-cell {
    min-height: 120px;
    padding: 5px;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    position: relative;
  }
  .day-number {
    font-size: 14px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .other-month {
    background-color: #f8f9fa;
    color: #6c757d;
  }
  .event {
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 4px;
    border-radius: 4px;
    font-size: 10px;
    color: white;
    white-space: normal;
    word-wrap: break-word; 
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .event-personal {
    background-color: #0d6efd;
  }
  .event-vacation {
    background-color: #198754;
  }
  .event-sick {
    background-color: #6c757d;
  }
  .event-pending {
    opacity: 0.7;
  }
  .today {
    background-color: #fff8e6;
  }
  
  /* Mobile view toggle and list view styles */
  .view-toggle {
    display: none; /* Hidden on all devices - no longer needed */
  }
  
  /* List view for mobile */
  .list-view {
    display: none;
  }
  
  /* Automatic view switching based on screen size */
  @media (max-width: 920px) {
    .calendar-grid {
      display: none; /* Hide calendar on mobile */
    }
    
    .list-view {
      display: block; /* Show list view on mobile */
    }
  }
  
  .list-item {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .list-date {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .list-event {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  
  .list-event .event {
    margin-top: 0;
    margin-right: 8px;
    display: inline-block;
    font-size: 12px;
  }