.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #d50032;
    border: 1px solid #d50032;
}

.DateInput {
    width: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(2rem + 5px);
    padding: 0 8px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
}

.DateInput_input {
    font-size: inherit;
    padding: 0;
}

.DateInput_input__focused {
    border-bottom: 2px solid #d50032;
}

.DateRangePickerInput {
    display: flex;
    min-width: 16rem;
    align-items: flex-end;
}

.DateRangePickerInput .DateInput {
    height: unset;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #d50032;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #d50032;
}

.is-invalid .SingleDatePickerInput {
    border-color: #dc3545;
}

.is-invalid .invalid-feedback {
    display: block;
}

.SingleDatePicker {
    width: 100%;
    height: 100%;
}

.SingleDatePicker_picker {
    z-index: 3 !important;
}

.SingleDatePickerInput {
    width: 100%;
    height: 50%;
}

.SingleDatePickerInput__withBorder {
    border: none;
}

.bootstrap-timepicker .form-control.time-picker-input {
    color: #495057;
    text-align: left;
    border-color: #ced4da;
    background-color: #fff;
    font-size: 16px;
}

.DayPicker--circle {
    .CalendarDay.CalendarDay_1.CalendarDay__default.CalendarDay__default_2 {
        border: none;
        border-radius: 50%;
    }
}

.highlight-date {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: #A2A2A2;
    width: 100%;
    height: 100%;
}

.highlight-date.highlight-date--current {
    background: #d50032;
}

.CalendarDay__highlighted_calendar:active, .CalendarDay__highlighted_calendar:hover {
    background: #d50032;
    color: #fff;
}

.CalendarDay__highlighted_calendar {
    background: #ffb3c5;
}

/**************************************************************************************/
/* ---------------------------------------------------------------------------------- */
/*                     Styles only for modal booking                                  */
/* -----------------------------------------------------------------------------------*/

.modalItinerary .CalendarDay__selected,
.modalItinerary .CalendarDay__selected:active,
.modalItinerary .CalendarDay__selected:hover {
    background: #d50032;
    border: 1px solid #d50032;
}

.modalItinerary .DateInput {
    width: 100%;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: calc(2.99rem + 4px);
    padding: 0 8px;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
}

.modalItinerary .DateInput_input {
    font-size: inherit;
    padding: 0;
}

.modalItinerary .DateInput_input__focused {
    border-bottom: 2px solid #d50032;
}

.modalItinerary .DateRangePickerInput {
    display: flex;
    min-width: 16rem;
    align-items: flex-end;
}

.modalItinerary .DateRangePickerInput .DateInput {
    height: unset;
}

.modalItinerary .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #d50032;
}

.modalItinerary.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #d50032;
}

.modalItinerary .is-invalid .SingleDatePickerInput {
    border-color: #dc3545;
}

.modalItinerary .is-invalid .invalid-feedback {
    display: block;
}

.modalItinerary .SingleDatePicker {
    width: 100%;
    height: 100%;
}

.modalItinerary .SingleDatePickerInput {
    width: 100%;
    height: 50%;
}

.modalItinerary .SingleDatePickerInput__withBorder {
    border: none;
}

.modalItinerary .bootstrap-timepicker .form-control.time-picker-input {
    color: #495057;
    text-align: left;
    border-color: #ced4da;
    background-color: transparent;
    font-size: 16px;
}

.modalItinerary .DayPicker--circle {
    .CalendarDay.CalendarDay_1.CalendarDay__default.CalendarDay__default_2 {
        border: none;
        border-radius: 50%;
    }
}

.modalItinerary .highlight-date {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: $light-grey;
    width: 100%;
    height: 100%;
}

.modalItinerary .highlight-date.highlight-date--current {
    background: $primary-color;
}
