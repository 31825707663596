@page {
  margin: 1cm;
  padding: 0;
}

.print-jump {
  page-break-after: always;
}

#printDiv .logoPrintPdf{
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }
  .fixed-print {
    font-size: 1.6rem !important;
  }
  .margin-print {
    margin: 2rem;
  }
  .reset-print {
    background: white !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .flex-print-row {
    flex-direction: row !important;
  }
  .img-print {
    max-height: 250px;
  }

  #printDiv {
      background-color: white;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0% 9.75% !important;
  }

  #printDiv img {
    background-color: #fff;
    border: none;
    border-radius: 0;
  }

  #printDiv .logoPrintPdf{
    display: inline;
  }
}