button.action {
    line-height: 0;
}

.action_icon {
    min-height: 1.4rem;
    min-width: 3rem;
    max-height: 1.4rem;
    max-width: 3rem;
}

.action_icon.action_icon-secondary {
    fill: #6c757d;
}

.action_icon-primary {
    fill: $primary-color;
}

button:hover .action_icon.action_icon-secondary,
.action_icon.action_icon-secondary:hover {
    fill: white;
}

.btn-outline-primary:hover .action_icon-primary{
    fill: white;
}
