.btn-hover {
  all: unset;
  cursor: pointer;
  color: black;
  transition: color 0.3s ease;
}

.btn-filter {
  all: unset;
  cursor: pointer;
}

.btn-hover:hover {
  color: rgb(0, 153, 255);
}

.btn-active {
  transform: scale(1.2);
  transition: transform 0.2s ease;
}

.contenedor {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.checkbox-item {
  flex: 1 0 200px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.CalendarMonth_table {
  display: table;
}