.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #333F48;
  }
  
  .circle-filled {
    background-color: #333F48;
  }
  
  .circle-empty {
    background-color: white;
  }
  