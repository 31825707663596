.custom-dropdown {
    border: none !important; 
    box-shadow: none !important;
    margin: 0;
    padding: 0;
  }
  
  .custom-dropdown .menu {
    margin-top: 5px;
    border: 1px solid #d4d4d5 !important; /* Establece un borde consistente */
    border-radius: 4px !important; /* Asegura esquinas redondeadas */
    box-shadow: none !important; /* Quita cualquier sombra que pueda ocultar el borde */
  }
  

  .custom-dropdown:focus,
  .custom-dropdown .menu:focus {
    outline: none !important; /* Quita el delineado azul */
    box-shadow: none !important; /* Quita cualquier sombra alrededor del dropdown */
  }
  
  .custom-dropdown .dropdown-content {
    padding: 10px;
    width: 200px; /* Ajusta el ancho del menú si es necesario */
  }
  
/* Deshabilitar el cambio de color al hacer hover */
.custom-dropdown .menu > div:hover {
  background-color: transparent !important; /* Deshabilita el fondo en hover */
  box-shadow: none !important; /* Elimina cualquier sombra al hacer hover */
}

/* Ajustar el estilo de los select */
.custom-dropdown .menu > div > select {
  background-color: white; /* Fondo blanco para los select */
  border: 1px solid #ccc; /* Borde gris claro */
  border-radius: 4px; /* Esquinas redondeadas */
  padding: 8px; /* Espaciado interno */
  color: #333; /* Color del texto */
  width: 100%; /* Asegurarse de que el select ocupe el ancho del contenedor */
  cursor: pointer;
}

/* Eliminar el contorno al enfocar */
.custom-dropdown .menu > div > select:focus {
  outline: none; /* Eliminar contorno por defecto */
  border-color: #007bff; /* Opcional: Cambiar borde al enfocar */
}

.my-table{
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-input {
  width: 500px;
  margin-bottom: 10px;
}

.mobile-cards {
  display: none;
}

.filter-group {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
}

.action-group {
  display: flex;
  gap: 5px;
}
.action-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px;
}

.badge-approved {
  background-color: #28a745;
}
.badge-pending {
  background-color: #ffc107;
  color: #212529;
}
.badge-personal {
  background-color: #0d6efd;
}
.badge-vacation {
  background-color: #198754;
}
.badge-sick {
  background-color: #6c757d;
}

@media (max-width: 768px) { 
  .search-input {
    width: 100%;
  }
  
  .filter-group {
    flex-direction: column;
  }
  
  .filter-group select {
    width: 100%;
  }
  
  .my-table{
    display: none;
  }
  /* Show the mobile cards */
  .mobile-cards {
    display: block;
  }
  
  .my-card {
    background-color: #e9ecef;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 12px; /* Slightly reduced padding */
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .my-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .card-name {
    font-weight: bold;
    font-size: 15px; /* Reduced from 16px */
    color: black;
  }
  
  .card-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px; /* Reduced from 10px */
    margin-bottom: 12px;
  }
  
  .card-field {
    margin-bottom: 6px; /* Reduced from 8px */
  }
  
  .card-label {
    font-size: 11px; /* Reduced from 12px */
    color: #6c757d;
    margin-bottom: 1px;
  }
  
  .card-value {
    font-size: 13px; /* Reduced from 14px */
  }
  
  .my-badge {
    font-size: 12px; /* Reduced badge font size */
    padding: 2px 6px; /* Reduced padding */
    min-width: 70px; /* Reduced min-width */
    color: white;
    display: inline-block;
    border-radius: 4px;
    min-width: 80px;
    text-align: center;
  }
  
  .my-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #e9ecef;
  }
}