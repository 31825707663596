/*
.autocomplete-container {
  position: relative;
  z-index: 4;
}
*/

.form-control::placeholder, .Select-placeholder {
  color: #c5c5c5 !important;
  font-style: italic;
}

.modalItinerary .form-contact {
  padding: .5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: #f5f5f6;
}

.modalItinerary .form-control {
  height: 3.6rem !important;
}

.modalItinerary .form-control:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.modalItinerary .form-group .form-control label {
  color: #c7c9c7;
  position: static;
  margin: 0 0 0 .5rem;
}

.modalItinerary .form-control.is-invalid.select-reset > div {
  border-color: #dc3545;
}

.modalItinerary .form-group {
  color: #626262;
  position: relative;
}

.modalItinerary .form-group label {
  position: absolute;
  z-index: 2;
  font-size: 0.7rem;
  left: 1rem;
  top: 1rem;
}

.modalItinerary .form-group.form-group--tour label {
  left: 0.7rem;
  top: 0.5rem;
}

.modalItinerary .form-group.form-group--tour .css-1hwfws3 {
  margin-top: 1rem
}

.modalItinerary .form-group.form-group--select .css-1hwfws3 {
  align-self: flex-end;
}

.modalItinerary .form-group input[type="checkbox"] {
  appearance: none;
  background-color: #FFF;
  height: initial !important;
  min-width: 25px;
  max-width: 25px;
  -webkit-appearance:none;
  min-height: 1.6rem;
}

.modalItinerary .form-group input[type="checkbox"]:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
  color: #99a1a7;
}

.modalItinerary .form-group input[type="checkbox"]:checked::after {
  content: '\2713';
  font-size: 32px;
  position: absolute;
  top: -10px;
  left: 0px;
  color: #1C1F2A;
}

.modalItinerary textarea.form-control {
  height: auto !important;
}
