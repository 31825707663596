.login-overlay .panel-body {
    min-height: 244px;
}
.has-feedback {
    position: relative;
}
.loginForm .form-control {
    height: 3.5rem !important;
}
.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
