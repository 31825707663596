@import 'configs/_fonts.scss';
@import "../../node_modules/react-select/dist/react-select.css";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "configs/color";
@import "components/button.css";
@import "components/jodit_editor.css";
@import "_override.css";
@import "bootstrap_reset.css";
@import 'datepicker_overrides';
@import 'components/_autosuggest.scss';
@import 'components/_form.scss';
@import "print.css";
@import "loader.css";

.flex-1 {
    flex: 1;
}

input::placeholder {
    color: #c5c5c5 !important;
    font-style: italic;
}

.flex-2 {
    flex: 2;
}

.timePickerLocation input::placeholder{
    color: #c5c5c5;
    font-style: italic;
}

.withOutBorder td {
    padding: 0;
}

br {
    border-top: 3px solid #ad464c;
    content: "";
    display: block;
    width: 100%;
    margin: 5px 0 15px 0;
}

body, .container, h1, h2, h3, h4, h5, h6, span, label, .DateInput_input, .swal2-popup .swal2-content {
    font-family: 'Montserrat' !important;
    font-weight: 400;
}

.crewContainer .rounded {
    border-radius: 0.5rem !important;
    -webkit-border-radius: 0.5rem !important;
    -moz-border-radius: 0.5rem !important;
    -ms-border-radius: 0.5rem !important;
    -o-border-radius: 0.5rem !important;
}

.navCrewOption {
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.navCrewOption:hover {
    border-color: #d50032 !important;
}

.verySmall {
    font-size: 9.5px !important;
}

.hide-column-header {
    display: none;
}

.br-black {
    border-top: 1px solid black;
}

.container {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.content-wrapper {
    position: relative;
    top: 76px;
    width: 100%;
}

.form-group input[type="radio"] {
    width: initial;
    height: initial;
    margin-top: 5px;
    margin-right: 10px;
}

.bold {
    font-weight: bold;
}

.bolder {
    font-weight: bolder;
}

.t-center {
    text-align: center;
}

.t-left {
    text-align: left;
}

.t-right {
    text-align: right;
}

.border-none {
    border: none !important;
}

.table-rowspan-striped thead {
    background-color: #212428;
    color: #fff;
}

.table-rowspan-striped tbody:nth-child(odd) {
    background: #CCC;
}

.table-rowspan-striped tbody:hover td[rowspan], .table-rowspan-striped tr:hover td {
    background: rgba(0, 0, 0, .075);
}

.small-shadow {
    box-shadow: -1px 5px 5px rgb(0 0 0 / 20%) !important;
}

h1,
h2,
h3 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.bg-primary {
    background-color: $primary-color !important;
}

img {
    background-color: $primary-color;
    border: 5px solid $primary-color;
    border-radius: 5px;
}

.img-unstyled {
    background-color: unset;
    border: none;
    border-radius: unset;
}

.is-invalid input {
    border-color: $primary-color;
}

.icon-upload {
    background-color: #fff;
}

.selectIcons img{
    border: None;
    border-radius: 0px !important;
}

.selectIcons .ui.dropdown .menu .selected.item, .ui.dropdown.selected {
    background-color: rgba(222, 2, 15, 0.2);
}

.selectIcons .ui.dropdown .menu>.item,
.selectIcons .ui.dropdown .menu .selected.item, .ui.dropdown.selected {
    display: flex;
    align-items: center;
}

.uploaderBanner .uploader {
    height: 160px;
    outline: None;
}

/*Style preloader*/
.loader-container {
    min-height: 140px;
}

.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
}

.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}
.custom-invalid-feedback{
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.resolution-information{
    color: #6c757d;
    font-size: 80%;
}
.table td.td-center {
    vertical-align: middle;
}

.clearImg img{
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

/*  Style to input places complete */

.autocomplete-container {
    position: relative;
    /* z-index: 4; */
}

.autocomplete-dropdown-container {
    background: white;
    border: 1px solid honeydew;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    max-width: 100%;
    z-index: 4;
}

.select-option {
    cursor: pointer;
}

.select-option:hover {
    background: $primary-color;
    color: white;
}

.nav-wrapper {
    padding: 0;
}

.nav-item {
    cursor: pointer;
    color: #fff;
}

.nav-item:hover {
    background-color: #333f48;
}

.nav-item .sub-nav-item {
    padding: 0.5rem 0rem 0.5rem 4rem;
    width: 100%;
    background-color: #333f48;
}

.nav-item .active-M {
    /* border-left: 6px solid $primary-color; */
    background-color: #333f48;
}

.nav-item .sub-nav-item:hover {
    background-color: #5b656c;
}

.nav-item .sub-nav-item a{
    display: list-item;
}

.nav-item .sub-nav-item a:hover{
    background-color: transparent;
    color: white;
    text-decoration: none;
}

.nav-item .sub-nav-item{
    color: $primary-color;
}

.nav-link .caret {
    float: right;
    width: 15px;
    transform: scale(0.7);
}

.menu_icon{
    font-size: inherit;
    width: 20px;
    height: 20px;
    padding-bottom: 0.25rem;
}

.modalItinerary .itinerary .nav-item > .text-dark.nav-link {
    color: #c7c9c7 !important;
}

.smallIcon{
    width: 20px;
    height: 20px;
}

.plusIconLocation {
    width: 60px;
    height: 60px;
}

.no-scroll-y{
    overflow-y: hidden !important;
}

.fileContainer{
    margin-right: 0 !important;
}

.filePickerSchedule{
    max-width: 100% !important;
    width: 100%;
}

.cartListShadow {
    box-shadow: -3px 5px 4px 0px rgba(0,0,0,0.2);
}

.cartListShadowBottom{
    position: relative;
}

.cartListShadowBottom::after {
    content: '';
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: -5px;
    background-color: transparent;
    box-shadow: 0px 6px 4px -2px rgba(0,0,0,0.2);
}

.t-green {
    color: #629C44
}

.t-primary{
    color: $primary-color;
}

.modalItinerary .Select-menu-outer {
    z-index: 3;
}

.table-head-sticky {
    overflow-y: auto;
    max-height: 800px;
}

.table-head-sticky thead th {
    position: sticky;
    top: 0;
}

.table-head-sticky tbody .center{
    text-align: center;
    vertical-align: middle;
}

.hover-primary:hover, .hover-primary:focus{
    background-color: $primary-color;
    color: white !important;
}

.clickable {
    cursor: pointer;
}

.modalItinerary .Select-placeholder,
.modal-body.modal-body--big .Select--single > .Select-control .Select-value {
    display: flex;
    align-items: center;
}

.modalItinerary .Select-value {
    display: flex;
    align-items: center;
}

.modalItinerary .Select-multi-value-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.modalItinerary .label .Select-input,
.modalItinerary .label .Select-placeholder,
.modalItinerary .label .Select-value{
    margin-top: 1rem;
}

.modalItinerary .mapCheckout img{
    background-color: transparent !important;
}

.modalItinerary .underline:hover {
    text-decoration: underline;
}

.modalItinerary .vehicle-select > .nav-item {
    width: 100%;
}

.modalItinerary .vehicle-select .nav-item .nav-link {
    background-color: hsl(206, 17%, 10%) !important;
    color: #6c757d !important;
}

.modalItinerary .vehicle-select .nav-item .nav-link.selected {
    background-color: #a5b4c0 !important;
    color: #f8f9fa !important;
}

.modalItinerary .vehicle-select .nav-item .nav-link:hover {
    border-color: transparent;
}

.modalItinerary .vehicle-select {
    width: 100%;
    border-bottom: none;
}

.modalItinerary ::placeholder {
    color: #c5c5c5 !important;
    font-style: italic;
}

.modalItinerary .itinerary {
    border: none;
}

.modalItinerary .itinerary .nav-link:hover {
    border-color: transparent;
    cursor: pointer;
}

.modalItinerary .itinerary .nav-link.active {
    background: $primary-color !important;
    border-color: $primary-color !important;
    color: white !important;
    opacity: 1;
}

.modalItinerary .itinerary .nav-link {
    background: white !important;
    color: $primary-color !important;
    text-align: center !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.modalItinerary .input-reset {
    border: none;
}

.modalItinerary .input-reset:focus {
    outline: none;
    box-shadow: none !important;
}

.modalItinerary .input-highlight:focus-within {
    -webkit-box-shadow: 0px 0px 5px 1px $primary-color;
    -moz-box-shadow: 0px 0px 5px 1px $primary-color;
    box-shadow: 0px 0px 5px 1px $primary-color;
}

.modal-dialog.modal-dialog--big {
    display: flex;
    max-width: 100%;
    min-height: 100%;
    margin-top: 0;
    align-items: center;
}

.modal-body.modal-body--big {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.modal-body__close {
    border: none;
    background: white;
    font-size: 2rem;
    color: $primary-color;
}

.modal-body__content {
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.modalItinerary .flex-1 {
    flex: 1;
}

.modalItinerary .flex-2 {
    flex: 2;
}

.modalItinerary .bg-light {
    background-color: white !important;
}

.modalItinerary .vehicle-card{
    color: #1C1F2A;
}

.modalItinerary .vehicle-card .vehicle-passenger{
    display: flex;
    justify-content: start;
}

.modalItinerary .vehicle-card .price {
    font-weight: 600; /* Semi-bold */
    font-family: 'Montserrat';
}

.modalItinerary .vehicle-card hr{
    border-top: 1px solid #707070;
}

.modalItinerary .vehicle-card .description{
    font-weight: 600; /* Semi-bold */
    font-family: 'Montserrat';
    font-size: 12px;
    color: #1C1F2A80 !important;
    opacity: 1 !important;
}

.modalItinerary .vehicle-card-title{
    font-size: 28px;
    font-weight: bold;
    font-family: 'Montserrat';
    text-align: left !important;
    text-align: start !important;
}

.modalItinerary .vehicle-card-item{
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 500; /* Medium */
    font-family: 'Montserrat';
}

.modalItinerary .vehicle-card-item img{
    height: 15px;
    margin-right: 5px;
}

.modalItinerary .btn-primary {
    color: #fff;
    background-color: #d50032;
    border-color: #d50032;
}

.modalItinerary .btn-outline-primary {
    color: #d50032 !important;
    border-color: #c7c9c7 !important;
}

.modalItinerary .btn-outline-primary:hover{
    color: #fff !important;
    background-color: #d50032 !important;
    border-color: #d50032 !important;
}

.modalItinerary .text-footer {
    font-size: 0.7rem;
}

.modalItinerary .container-promo {
    display: flex;
    padding: 0 0.5rem;
    flex-direction: column;
}

.modalItinerary .container-promo input,
.modalItinerary .container-promo button {
    width: 100%;
}

.modalItinerary .container-promo button {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.modalItinerary .font-info {
    font-size: 0.9rem;
    max-width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modalItinerary .bg-wizard {
    background-color: hsl(206, 17%, 10%);
    border: none;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
}

.modalItinerary .bg-wizard--available {
    cursor: pointer;
}

.modalItinerary .bg-wizard.bg-wizard--selected {
    background-color: hsl(206, 17%, 70%);
}

.crewAirlineDetail h5 {
    font-size: 1.15rem;
}

@media only screen and (max-width: 576px) {
    .modalItinerary img {
        background-color: #fff;
        border: none;
        border-radius: 0;
    }

    .modalItinerary .vehicle-card{
        padding: 1rem 1rem 1rem 1rem;
    }

    .modalItinerary .vehicle-card .vehicle-passenger .luggageIcon{
        margin-left: 0.5rem;
    }

    .modalItinerary .vehicle-card .price{
        font-size: 1.5rem;
    }

    .modalItinerary .vehicle-card .price,
    .modalItinerary .vehicle-card .price-includes{
        align-self: center;
    }

    .modalItinerary .vehicle-card .vehicle-card-image img{
        width: 100%;
    }
}

@media only screen and (min-width: 576px) {
    .modal-dialog.modal-dialog--big{
        width: 80%;
    }
    .modalItinerary img {
        background-color: #fff;
        border: none;
        border-radius: 0;
    }
    .modalItinerary .vehicle-card{
        padding: 1rem 1.5rem 1rem 1rem;
    }

    .modalItinerary .vehicle-card .vehicle-passenger .luggageIcon{
        margin-left: 1.5rem;
    }

    .modalItinerary .vehicle-card .price,
    .modalItinerary .vehicle-card{
        font-size: 1.5rem;
    }

    .modalItinerary .vehicle-card .price,
    .modalItinerary .vehicle-card .price-includes{
        align-self: center;
    }

    .modalItinerary .vehicle-card .vehicle-card-image,
    .modalItinerary .vehicle-card .vehicle-card-features{
        flex: Auto;
    }

    .modalItinerary .vehicle-card .vehicle-card-image{
        padding: 0rem 1rem;
        text-align: center;
    }

    .modalItinerary .vehicle-card .vehicle-card-image img{
        width: 75%;
    }

    .modalItinerary .vehicle-card .vehicle-card-features{
        padding-left: 15% !important;
        padding-right: 15% !important;
    }
}

@media only screen and (max-width: 768px) {
    /* .container {
        margin-left: 0;
        margin-right: 0;
    } */
}

@media only screen and (min-width: 768px) {
    .modalItinerary .vehicle-card{
        padding: 1rem 2rem 1rem 1rem;
    }

    .modalItinerary .vehicle-card .vehicle-passenger .luggageIcon{
        margin-left: 1.2rem;
    }

    .modalItinerary .vehicle-card .price{
        font-size: 1.7rem;
    }

    .modalItinerary .vehicle-card .vehicle-card-image,
    .modalItinerary .vehicle-card .vehicle-card-features{
        flex: 2;
    }

    .modalItinerary .vehicle-card .vehicle-card-features{
        width: 100%;
    }

    .modalItinerary .vehicle-card .vehicle-card-image{
        padding: 0rem 1rem;
    }

    .modalItinerary .vehicle-card .vehicle-card-image img{
        width: 100%;
    }

    .modalItinerary .vehicle-card .vehicle-card-features{
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .modalItinerary .btn {
        width: 50%;
        max-width: 18rem;
    }

    .modalItinerary .font-info {
        max-width: 100%;
    }

    .crewAirlineDetail h5 {
        font-size: 1.20rem;
    }
}

@media only screen and (min-width: 992px) {
    .modalItinerary .container-promo {
        flex-direction: row;
        padding: 0 0.5rem;
    }

    .modalItinerary .container-promo button {
        width: 50%;
    }

    .modalItinerary .container-promo button {
        margin-left: 1rem;
        margin-top: 0;
        margin-bottom: 0rem;
    }
    .crewAirlineDetail h5 {
        font-size: 1.25rem;
    }
}
/* $bp-xl: 1200px; */
@media only screen and (min-width: 1200px){
    .crewAdminMaxHeight {
        min-height: calc(100vh - 7rem);
    }
    .crewAirlineDetail h5 {
        font-size: 1.30rem;
    }
}

@media only screen and (min-width: 1366px){
    .crewAirlineDetail h5 {
        font-size: 1.35rem;
    }
}

@media only screen and (min-width: 1920px){
    .col-1080-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .crewAirlineDetail h5 {
        font-size: 1.4rem;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
