
/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital@0;1&display=swap');


.font-normal {
    font-weight: 400; /* Regular */
    font-family: 'Montserrat' !important;
}

.font-thin {
    font-weight: 100; /* Thin */
    font-family: 'Montserrat' !important;
}

.font-medium {
    font-weight: 500;
    font-family: 'Montserrat';
}

.font-semiBold{
    font-weight: 600;
    font-family: 'Montserrat';
}

.font-bold {
    font-weight: bold;
    font-family: 'Montserrat';
}