.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
}
.stats-content{
  margin-left: 200px;
  margin-right: 200px;
}

@media (min-width: 576px) {
    .container-sm, .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container-md, .container-sm, .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px;
    }
}
@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1320px;
    }
}

@media (max-width: 1600px) {
  .stats-content{
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 1200px) {
  .stats-content{
    margin-left: 50px;
    margin-right: 50px;
  }
}

h1 {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: bold;
}
h2 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 24px;
}
.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
button {
  padding: 6px 12px;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
}
button.active {
  background-color: #e9ecef;
}
.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.stat-card {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}
.stat-title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #6c757d;
}
.stat-value {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
}
.stat-percentage {
  font-size: 14px;
  color: #6c757d;
}
.approved-card {
  border-top: 4px solid #28a745;
}
.pending-card {
  border-top: 4px solid #ffc107;
}
.denied-card {
  border-top: 4px solid #dc3545;
}
.total-card {
  border-top: 4px solid #0d6efd;
}
.progress-container {
  margin-top: 30px;
  margin-bottom: 30px;
}
.progress-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.my-progress-bar-container {
  width: 100%;
  height: 25px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
}
.my-progress-bar {
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0 10px;
  white-space: nowrap; /* Evita que el texto haga que los elementos crezcan */
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px; /* Ajusta el mínimo tamaño de cada barra */
  flex-grow: 1;
}
.my-personal-bar {
  background-color: #ffc107;
}
.my-vacation-bar {
  background-color: #198754;
}
.my-sick-bar {
  background-color: #6c757d;
}
.chart-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}
.chart {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 20px;
  min-width: 0;
}
.chart-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: normal;
}
.bar-chart {
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.bar-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bar {
  width: 30px;
}
.bar-label {
  margin-top: 10px;
}
.donut-chart {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  background: conic-gradient(
    #0d6efd 0% 75%, 
    #198754 75% 87.5%, 
    #6c757d 87.5% 100%
  );
}
.donut-center {
  position: absolute;
  width: 120px;
  height: 120px;
  background: white;
  border-radius: 50%;
  top: 40px;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.donut-value {
  font-size: 24px;
  font-weight: bold;
}
.donut-label {
  font-size: 14px;
  color: #6c757d;
}
.legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}
.legend-color {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr 1fr;
  }
  .chart-container {
    grid-template-columns: 1fr;
  }
  .stats-content{
    margin-left: 0px;
    margin-right: 0px;
  }
  
}

@media (max-width: 480px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
}